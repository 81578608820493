const headerSearch = document.querySelector('[data-directory-header-search]');

headerSearch.addEventListener('submit', (e) => {
  const cityInput = headerSearch.querySelector(
    '[data-directory-header-search-city]',
  );
  if (!cityInput.value) {
    e.preventDefault();
    alert('Please search for and select a city.');
  }
});
